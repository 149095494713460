import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CreateRoom from "../../../components/CreateRoom/CreateRoom";
import DatePicker from "../../../components/DatePicker/DatePicker";
import Filter from "../../../components/Filter/Filter";
import { Icon } from "../../../components/Icon";
import TimerPR from "../../../components/TimerPR/TimerPR";
import getDate from "../../../helpers/getDate";
import getWordEnding from "../../../helpers/getWordEnding";
import { createRoom, getUsersHistoryList } from "../../../redux/actions/room";
import { socket } from "../../../socket";

const sortOptions = [
    { id: "createdAt", value: "За датою створення" },
];

const ProfileRoomsPage=()=>{
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const list = useSelector(state=>state.list)
    const [isOpenCreate, setIsOpenCreate] = useState(false);
    const [sort,setSort]=useState('asc');
    const [ready, setReady] = useState(false);
    const [formMy, setFormMy] = useState([]);
    const [historyRoom, setHistoryRoom] = useState([]);
    const [sortValue,setSortValue]=useState('createdAt');
    const [filterCreate, setFilterCreate] = useState({});
    const user = useSelector((state) => state.currentUser.user);
    const dispatch = useDispatch();
    const navigate=useNavigate();

    const [filter, setFilter] = useState(() => {
        // Initialize filter from localStorage if available
        const savedFilter = localStorage.getItem("profileRoomsPage");
        return savedFilter ? JSON.parse(savedFilter) : {};
    });

    const changeHandler = (e) => {
        const { id, value } = e.target;
        let updatedFilter = { ...filter };

        if (e.target.tagName === 'SELECT' && e.target.accessKey) {
            updatedFilter[e.target.accessKey] = value;
        }
        else if (id === "dateFrom" || id === "dateTo") {
            updatedFilter[id] = getDate(value, "yyyy/mm/dd", "-");
        } 
        else if (id === "all" && value.length<3 && value.length>0) {
           
        } 
        else if (value.length<1) {
            delete updatedFilter[id];
        } 
        else {
            updatedFilter[id] = value;
        }
        setFilter(updatedFilter);
        localStorage.setItem("profileRoomsPage", JSON.stringify(updatedFilter));
    };
    const initFunc = useCallback((page = 1,fil) => {
        let filt={...filter}
        if(fil){
            if (fil.target.id === "dateFrom" || fil.target.id === "dateTo") {
                filt[fil.target.id]= getDate(fil.target.value, "yyyy/mm/dd", "-");
            } 
        }
        let data = {};
        data.limit = 20;
        data.offset = (page - 1) * data.limit;
        data.filter = filt;
        data.sort={};
        data.sort[sortValue]=sort;
        dispatch(getUsersHistoryList(data)).then((res) => {
            setHistoryRoom(res);
            setReady(true);
        });
    },[filter]);


    const getMyGameInit=()=>{
        socket.emit('getMyRooms',{});
    }
    const getMyRooms=(resp)=>{
        setFormMy(resp);
        setReady(true)
    }
    const wasCreated=(resp)=>{
        navigate('/room/'+resp);
    }
    
    socket.on('roomsMyList',getMyRooms);
    socket.on('wasCreated',wasCreated);

    useEffect(() => {
        if (!ready) {
            initFunc();
            getMyGameInit();
        }
    }, [ready]);

    const resetFilter=()=>{
        localStorage.removeItem('profileRoomsPage');
        setFilter({});
    }


    return(<>
        <div className="profileSection profileSectionBg">
            <h2>{t('actual_rooms')}
                <div className="infoHelp"><Icon type='info'/></div>
            </h2>
            <div className="roomList">
                {formMy.length>0 &&
                formMy.map(el=>{
                    return(
                    <div className="roomItem prFlexRow">
                        <div className="roomItemLink" style={{cursor:'pointer'}} onClick={()=>navigate('/room/'+el.id)}>{el.id}</div>
                        <div className="roomItemIcon">
                            <Icon type='time'></Icon>
                        </div>
                        <div className="roomItemTime">
                            {<TimerPR initialSeconds={el.termExistence}></TimerPR>}
                        </div>
                        <div className="roomItemBlock">
                            <div className="roomItemBlockHeader">{t('game')}</div>
                            <div className="roomItemBlockValue">{el.game.name}</div>
                        </div>
                        <div className="roomItemMoney">
                            <div className="roomItemBlockHeader">{t('input_money')}</div>
                            <div className="roomItemMoneyValue prFlexRow">{el.price} <Icon type='fTocken'></Icon></div>
                        </div>
                        <div className="">
                            <div className="roomItemBlockHeader">{t('type')}</div>
                            <div className="roomItemBlockValue">{el.isPrivateRoom===1?'Приватна':'Публічна'}</div>
                        </div>
                        <div className="roomItemIcon roomItemBlock dFlex" style={{gap:'10px'}}>
                                {el.platform.map(elem=>{
                                    return(<> 
                                                {elem=='09ba716e-3597-4751-84b3-8fdf453cd072'&&
                                                    <Icon type='pc'></Icon>
                                                }
                                                {elem=='09ba716e-3597-4751-84b3-8fdf453cd073' &&
                                                <Icon type='ps'></Icon>
                                                }
                                                {elem=='09ba716e-3597-4751-84b3-8fdf453cd071' &&
                                                <Icon type='xbox'></Icon>
                                                }
                                    </>)
                                })}
                        </div>
                        <div className="roomItemBlock">
                            <div className="roomItemBlockHeader">{t('format')}</div>
                            <div className="roomItemBlockValue">{el.format} х {el.format}</div>
                        </div>
                        <div className="roomItemBlock">
                            <div className="roomItemBlockHeader">{t('status')}</div>
                            <div className="roomItemBlockValue">{list[el.status]}</div>
                        </div>
                        <div className="roomItemBlock">
                            <div className="roomItemBlockHeader">{t('time_created')}</div>
                            <div className="prFlexRow"> 
                            <div className="roomItemBlockValue"><Icon type='time'></Icon>   {getDate(el.createdAt.replace('T',' '),'hh:mm',":")}</div>
                                        <div style={{marginLeft:'18px'}} className="roomItemBlockValue"><Icon type='calendar'></Icon> {getDate(el.createdAt.replace('T',' '),'dd/mm/yyyy',".")}</div>
                            </div>
                        </div>
                    </div>
                    )
                })
               
                }
                
            </div>
        </div>


        <div className="profileSection">
            <div className="prFlexRow profileSectionHeader">
                <h2 >{t('close_rooms')}
                <div className="infoHelp"><Icon type='info'/></div></h2>
                <div className="prFlexRow" style={{gap:'25px'}}>
                    <button className="prButton prButtonMain" onClick={() => setIsOpenCreate(true)}>{t('create_room')}</button>
                    <button className="prButton prButtonMainP prButtonSecond prFlexRow" onClick={() => setIsOpen(true)}>
                        <Icon type="filter" /> {t("filter_show")}
                    </button>
                </div>
                {/* <a className="profileSectionHeaderLink link">{t('all_games')}<Icon type='rightarrow'/></a> */}
            </div>
            <div className="filterBlock">
                <div className="prFlexRow">
                    <div className="prFlexRow">
                        <div className="prFlexRow filterBlockFilter">
                            <div className="filterBlockSortText">{t("filter_period")}:</div>
                            <DatePicker id="dateFrom" value={(filter.dateFrom?.length>1 && getDate(filter.dateFrom,'dd/mm/yyyy','.')) || ''} onChange={changeHandler} onBlur={initFunc} icon={true} />
                            <div>-</div>
                            <DatePicker id="dateTo" value={(filter.dateTo?.length>1 && getDate(filter.dateTo,'dd/mm/yyyy','.')) || ''} onChange={changeHandler}  onBlur={initFunc} icon={true} />
                        </div>
                        <div className="filterBlockSort prFlexRow">
                            <div className="filterBlockSortText">{t("filter_sort")}:</div>
                            <div className="filterBlockSortValue prFlexRow">
                                <div className={sort=='asc'?'norm':'pervert'}><Icon type="filterArrow" /></div>
                                <div>{sortOptions[0].value}</div>
                                <Icon type="selectArrow" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="filterBlockFilteredText">
                    <div>Знайдено {historyRoom?.all?.length} {getWordEnding(historyRoom?.all?.length)}</div>

                </div>
            </div>
            <div className="roomList">
                {historyRoom?.all?.length>0 &&
                    historyRoom?.all?.map(elem=>{
                        console.log(elem);
                        return(<>
                            <div className="roomItem prFlexRow">
                                <div className="roomItemLink">{elem.id}</div>
                                <div className="roomItemBlock">
                                    <div className="roomItemBlockHeader">{t('game')}</div>
                                    <div className="roomItemBlockValue">{elem.game.name}</div>
                                </div>
                                <div className="prFlexRow">
                                    <div className="roomItemMoney">
                                        <div className="roomItemBlockHeader">{t('input_money')}</div>
                                        <div className="roomItemMoneyValue prFlexRow">{elem.price} <Icon type='fTocken'></Icon></div>
                                    </div>
                                    <div className={elem.result=='0'?"roomItemResult loose":"roomItemResult win"}>
                                        <div className="roomItemBlockHeader">{t('result')}</div>
                                        <div className="roomItemMoneyValue prFlexRow "><Icon type={elem.result=='0'?'loose':'win'}></Icon> {elem.result=='0'?'-':'+'} {elem.price}$</div>
                                    </div>
                                </div>
                                <div className="">
                                    <div className="roomItemBlockHeader">{t('type')}</div>
                                    <div className="roomItemBlockValue">{elem.isPrivate?'Приватна':'Публічна'}</div>
                                </div>
                                <div className="roomItemIcon roomItemBlock dFlex" style={{gap:'10px'}}>
                                {elem.platform.map(el=>{
                                    return(<> 
                                                {el=='09ba716e-3597-4751-84b3-8fdf453cd072'&&
                                                    <Icon type='pc'></Icon>
                                                }
                                                {el=='09ba716e-3597-4751-84b3-8fdf453cd073' &&
                                                <Icon type='ps'></Icon>
                                                }
                                                {el=='09ba716e-3597-4751-84b3-8fdf453cd071' &&
                                                <Icon type='xbox'></Icon>
                                                }
                                    </>)
                                })}
                                </div>
                                <div className="roomItemBlock">
                                    <div className="roomItemBlockHeader">{t('format')}</div>
                                    <div className="roomItemBlockValue">{elem.format} х {elem.format}</div>
                                </div>
                                <div className="roomItemBlock">
                                    <div className="roomItemBlockHeader">{t('role_room')}</div>
                                    <div className="roomItemBlockValue">{elem.createdBy===user.id ? 'Організатор':elem.isCaptain?'Капітан':'Учасник'}</div>
                                </div>
                                <div className="roomItemBlock">
                                    <div className="roomItemBlockHeader">{t('time_created')}</div>
                                    <div className="prFlexRow"> 
                                        <div className="roomItemBlockValue"><Icon type='time'></Icon>   {getDate(elem.createdAt.replace('T',' '),'hh:mm',":")}</div>
                                        <div style={{marginLeft:'18px'}} className="roomItemBlockValue"><Icon type='calendar'></Icon> {getDate(elem.createdAt.replace('T',' '),'dd/mm/yyyy',".")}</div>
                                    </div>
                                </div>
                            </div> 
                        </>)

                    })
                }              
            </div>
        </div>
        {isOpen && <Filter type='rooms' resetFilter={resetFilter} accept={initFunc} filter={filter} setFilter={setFilter} onChange={changeHandler} isOpen={isOpen} setIsOpen={setIsOpen} />}
        {isOpenCreate && <CreateRoom isOpen={isOpenCreate} setIsOpen={setIsOpenCreate} ></CreateRoom>}

    </>)
}

export default ProfileRoomsPage;