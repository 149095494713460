import React, { useState,useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Icon } from "../../../components/Icon";
import getDate from "../../../helpers/getDate";
import { getSupportsList } from "../../../redux/actions/support";


const SupportListPage =()=>{
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const support = useSelector((state) => state.support);
    const [ready,setReady]=useState(false);
    const list=useSelector(state=>state?.list)
    const [isOpen, setIsOpen] = useState(false);
    const [page,setPage]=useState(1);
    const [filter, setFilter] = useState(() => {
        const savedFilter = localStorage.getItem("supportPage");
        return savedFilter ? JSON.parse(savedFilter) : {};
    });

    const initFunc=(page)=>{
        let data = {};
        data.limit = 20;
        data.offset = (page - 1) * data.limit;
        data.filter = filter;
        data.sort={};
        dispatch(getSupportsList(data)).then(res=>{
            setReady(true);
        })
    }

    const createClick = () => {
        navigate(`/supportCreate`);
    };

    useEffect(() => {
        if(!ready){
            initFunc();
        }
        
    }, [ready]);


    console.log(support)

    return(<>
        <div className="profileSection" style={{marginBottom:'0px'}}>
            <div className="prFlexRow profileSectionHeader" style={{marginBottom:'40px'}}>
                <h2 >{t('support')}
                    <div className="infoHelp"><Icon type='info'/></div>
                </h2>
                <div className="prFlexRow gameSection" style={{gap:'15px'}}>
                    <button className="prButton prButtonMain" onClick={createClick}>
                            {t("support_create")}
                    </button>
                    <button className="prButton prButtonMain prButtonSecond prFlexRow" onClick={() => setIsOpen(true)}>
                        <Icon type="filter" /> {t("filter_show")}
                    </button>
                </div>
            </div>

            <div className="roomList">
                {support?.list?.length>0 &&
                support?.list.map((el,index)=>{
                    return(
                    <div key={index} className="roomItem roomItemSupport prFlexRow">
                        <div className="roomItemLink2" style={{cursor:'pointer'}} onClick={()=>navigate('/supportRoom/'+el.id)}>
                            <div className="roomItemBlockHeader">{el.id}</div>
                            <div className="roomItemBlockValue">{el.message}</div>
                        </div>        
                        <div className="roomItemBlock">
                            <div className="roomItemBlockHeader">{t('Статус запиту')}</div>
                            <div className="roomItemBlockValue">{list[el.status]}</div>
                        </div>
                        <div className="roomItemBlock">
                            <div className="roomItemBlockHeader">{t('Відділ')}</div>
                            <div className="roomItemBlockValue">{list[el.department]}</div>
                        </div>
                        <div className="roomItemBlock">
                            <div className="roomItemBlockHeader">{t('Час оновлення')}</div>
                            <div className="prFlexRow"> 
                                <div className="roomItemBlockValue"><Icon type='time'></Icon> {getDate(el.updatedAt?.replace('T',' '),'hh:mm',':')}</div>
                                <div style={{marginLeft:'18px'}} className="roomItemBlockValue"><Icon type='calendar'></Icon>{getDate(el.updatedAt?.replace('T',' '),'dd/mm/yyyy','.')}</div>
                            </div>
                        </div>
                         <div className="roomItemBlock">
                            <div className="roomItemBlockHeader">{t('time_created')}</div>
                            <div className="prFlexRow"> 
                                <div className="roomItemBlockValue"><Icon type='time'></Icon> {getDate(el.createdAt?.replace('T',' '),'hh:mm',':')}</div>
                                <div style={{marginLeft:'18px'}} className="roomItemBlockValue"><Icon type='calendar'></Icon>{getDate(el.createdAt?.replace('T',' '),'dd/mm/yyyy','.')}</div>
                            </div>
                        </div>
                    </div>  )

                })
                }

            </div>

        </div>
    </>)

}

export default SupportListPage;