import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CreateRoom from "../../../components/CreateRoom/CreateRoom";
import Filter from "../../../components/Filter/Filter";
import { Icon } from "../../../components/Icon";
import TimerPR from "../../../components/TimerPR/TimerPR";
import getDate from "../../../helpers/getDate";
import getWordEnding from "../../../helpers/getWordEnding";
import { createRoom, getRoomsList } from "../../../redux/actions/room";
import { socket } from "../../../socket";



const sortOptions = [
    { id: "createdAt", value: "Вартість" },
];

const RoomsGameItemPage=(props)=>{
    const { t } = useTranslation();
    const roomsList=useSelector(state=>state?.room?.roomsList)
    const list=useSelector(state=>state?.list)
    const navigate=useNavigate();
    const dispatch = useDispatch();
    const [isOpenCreate, setIsOpenCreate] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [sort,setSort]=useState('asc');
    const [ready,setReady]=useState(false);
    const [filterCreate, setFilterCreate] = useState({});
    const [filter, setFilter] = useState({});


    const resetFilter=()=>{
        setFilter({});
    }
    

    const changeHandler = (e) => {
        const { id, value } = e.target;
        let updatedFilterCreate = { ...filter};

        if (e.target.tagName === 'SELECT' && e.target.accessKey) {
            updatedFilterCreate[e.target.accessKey] = value;
        }
        else if (value.length<1) {
            delete updatedFilterCreate[id];
        }
        else {
            updatedFilterCreate[id] = value;
        }
        setFilter(updatedFilterCreate);
    };

    const initFunc=()=>{
        dispatch(getRoomsList({gameId:props.game.id,...filter})).then(res=>{

        })
    };
    const selectClick=(e)=>{
        e.preventDefault();
        let arr = [];

        if( filterCreate.platform?.length>0){
            arr=[...filterCreate.platform]
        }
        let el = e.target.id;
        if (arr.indexOf(el) !== -1) {
            arr = arr.filter(item => item !== el);
        } else {
            arr.push(el);
        }
        setFilterCreate({
            ...filterCreate,
            platform: arr
        });
    }
    const wasCreated=(value)=>{
        navigate('/room/'+value);
    }

    socket.on('wasCreated',wasCreated);

    useEffect(() => {
        initFunc();
        const intervalId = setInterval(initFunc, 10000);
        return () => clearInterval(intervalId);
    }, [ready,filter]);
    



    return(<>
    <div className="prFlexRow" style={{marginBottom:'40px'}}>
        <div className="gameListInfo">
            На цій сторінці ви бачите лише кімнати, створені в межах гри <strong>“{props.game.name}”</strong>.  Тут відображаються лише кімнати зі статусом “<strong>Пошук учасників</strong>”. Кімнати із іншими статусами, де ви є учасникм чи організатором можна знайти в особистому кабінеті в розділі <a onClick={()=>navigate('/profile/rooms')}>Кімнати</a>
        </div>
        <div className="gameListSeparator">

        </div>
        <div>
            <button className="prButton prButtonMain prFlexRow" style={{gap:'5px', fill:"white"}} onClick={() => setIsOpenCreate(true)}>
                <Icon type="plus" />
                {t('create_room')}
            </button>

        </div>
    </div>
    <div className="filterBlock prFlexRow" style={{width:'100%'}}>
        <div className="prFlexRow">
            <div className="prFlexRow"> 
                <div>Знайдено {roomsList?.length} {getWordEnding(roomsList?.length)}</div>
                <div className="filterBlockSort prFlexRow">
                        <div className="filterBlockSortText">{t("filter_sort")}:</div>
                        <div className="filterBlockSortValue prFlexRow" onClick={()=>setSort(sort=='asc'?'desc':'asc')}>
                    <div className={sort=='asc'?'norm':'pervert'}><Icon type="filterArrow" /></div>
                        <div>{sortOptions[0].value}</div>
                        <Icon type="selectArrow" />
                    </div>
                </div>
            </div>
        </div>
        <div>
            <button className="prButton prButtonMain prButtonSecond prFlexRow" onClick={() => setIsOpen(true)}>
                <Icon type="filter" /> {t("filter_show")}
            </button>
        </div>
    </div>
    <div className="profileSection">
    <div className="roomList">
        {roomsList?.length>0&& roomsList.map(el=>{
            return(
                <div className="roomItem prFlexRow">
                    <div className="roomItemLink" style={{cursor:'pointer'}} onClick={()=>navigate('/room/'+el.id)}>{el.id}</div>

                    <div className="roomItemTime prFlexRow">
                        <div className="roomItemIcon">
                            <Icon type='time'></Icon>
                        </div>
                        <TimerPR initialSeconds={el.termExistence} />
                    </div>

                    <div className="roomItemMoney">
                        <div className="roomItemBlockHeader">{t('input_money')}</div>
                        <div className="roomItemMoneyValue prFlexRow">{el.price} <Icon type='fTocken'></Icon></div>
                    </div>
                    <div className="roomItemIcon dFlex" style={{gap:'5px'}}>
                        {el.platform.map(elem=>{
                            return(<> 
                                        {elem=='09ba716e-3597-4751-84b3-8fdf453cd072'&&
                                            <Icon type='pc'></Icon>
                                        }
                                        {elem=='09ba716e-3597-4751-84b3-8fdf453cd073' &&
                                        <Icon type='ps'></Icon>
                                        }
                                        {elem=='09ba716e-3597-4751-84b3-8fdf453cd071' &&
                                        <Icon type='xbox'></Icon>
                                        }
                            </>)
                        })}
                                    
                    </div>
                    <div className="roomItemBlock">
                        <div className="roomItemBlockHeader">{t('format')}</div>
                        <div className="roomItemBlockValue">{el.format+' x '+el.format}</div>
                    </div>
                    <div className="roomItemBlock">
                        <div className="roomItemBlockHeader">{t('status')}</div>
                        <div className="roomItemBlockValue">{list[el.status]}</div>
                    </div>
                    <div className="roomItemBlock">
                        <div className="roomItemBlockHeader">{t('time_created')}</div>
                        <div className="prFlexRow"> 
                            <div className="roomItemBlockValue"><Icon type='time'></Icon> {getDate(el.updatedAt.replace('T',' '),'hh:mm',':')}</div>
                            <div style={{marginLeft:'18px'}} className="roomItemBlockValue"><Icon type='calendar'></Icon>{getDate(el.updatedAt.replace('T',' '),'dd/mm/yyyy','.')}</div>
                        </div>
                    </div>
                </div>  
            )
        })}
        
    </div>
    </div>
    {isOpenCreate && <CreateRoom game={props.game} isOpen={isOpenCreate} setIsOpen={setIsOpenCreate} ></CreateRoom>}
    {isOpen && <Filter type='roomsFilter' selectClick={selectClick} game={props.game} needCheckedC={true} resetFilter={resetFilter} accept={initFunc} filter={filter} setFilter={setFilter} onChange={changeHandler} isOpen={isOpen} setIsOpen={setIsOpen} />}

    </>)
}

export default RoomsGameItemPage;