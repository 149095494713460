import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logout } from '../../redux/actions/auth';
import { socket } from '../../socket';
import CreateRoom from '../CreateRoom/CreateRoom';
import { Icon } from '../Icon';
import TimerPR from '../TimerPR/TimerPR';

const HeaderAuth = (props) => {


    const { t } = useTranslation();
    const user = useSelector((state) => state.currentUser.user);
    const list = useSelector(state=>state.list)
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [openRight, setOpenRight] = useState(false);
    const [openNotifications, setOpenNotifications] = useState(false);
    const [notifications, setNotifications] = useState([]);
    const [openRoomsList, setOpenRoomsList] = useState(false);
    const [rooms, setRooms] = useState([]);
    const [isOpenCreate, setIsOpenCreate] = useState(false);

    

    const menuRef = useRef(null); // Ref for the left side menu
    const menuRightRef = useRef(null); // Ref for the right side menu
    const menuNotificationRef = useRef(null); // Ref for the right side menu


    const logOutClick = () => {
        dispatch(logout());
    };

    const setTopicClick = (topic) => {
        props.setTopic(topic);
        localStorage.setItem('Theme', topic);
    };

    const openMenu = () => {
        setOpen(!open);
    };

    const openMenuRight = () => {
        setOpenRight(!openRight);
    };
    const openNotification = () => {
        if(!openNotifications){
            socket.emit('privateMessageGet',{});
            socket.emit('privateMessageRead',{});
        }
        setOpenNotifications(!openNotifications);
    };

    const getNotifications=(resp)=>{
        setNotifications(resp);
    }
    const getMyRooms=(resp)=>{
        setRooms(resp);
    }
    const wasCreated=(resp)=>{
        navigate('/room/'+resp)
    }

    const setOpenRoomsListClick=()=>{
        setOpenRoomsList(!openRoomsList);
        socket.emit('getMyRooms',{});
    }

    socket.on('privateMessage',getNotifications);
    socket.on('roomsMyList',getMyRooms);
    socket.on('wasCreated',wasCreated);

    


    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setOpen(false);
            }
            if (menuRightRef.current && !menuRightRef.current.contains(event.target)) {
                setOpenRight(false);
            }
            if(menuNotificationRef.current && !menuNotificationRef.current.contains(event.target)) {
                setOpenNotifications(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [menuRef, menuRightRef,menuNotificationRef]);

    return (
        <>
        <div className='headerPosition'>
            <div className='headerAuth prFlexRow'>
                <div className='headerLogo'>
                    <div className='headerLogoLogo'  onClick={() => navigate('/')}>
                        <Icon type='logo'></Icon>
                    </div>

                    <div className='headerLogoText'  onClick={() => navigate('/')}>
                        Play Room
                    </div>
                    <div className='headerTheme'>
                        <div onClick={() => setTopicClick('black')} className={props.topic === 'white' ? 'selected' : 'selected active'}>
                            <Icon type='darkTheme'></Icon>
                        </div>
                        <div onClick={() => setTopicClick('white')} className={props.topic === 'black' ? 'selected' : 'selected active'}>
                            <Icon type='lightTheme'></Icon>
                        </div>
                    </div>
                </div>
                <div className='prFlexRow' style={{ gap: '30px' }}>
                    {rooms.length>0 && <div className='userAttempt prFlexRow'>
                        <div className='addUserAttempt' onClick={()=>setIsOpenCreate(true)}> <Icon type='plus'></Icon></div>
                        <div style={{cursor:'pointer'}} onClick={()=>navigate('/room/'+rooms[0].id)}>
                            <div className='prFlexRow'>
                                <Icon type='playController'></Icon>
                                <div className='userAttemptStatus'>
                                    {list[rooms[0].status]}
                                </div>
                            </div>
                            <div className='userAttemptTime'>
                                <TimerPR initialSeconds={rooms[0].termExistence} />
                            </div>
                        </div>
                        <div className='numberUserAttempt' onClick={setOpenRoomsListClick}>{rooms.length}</div>
                    
                        {openRoomsList &&
                        <div className='userAttemptList'>
                            {rooms.map((el,index)=>{
                                if(index===0){
                                    return null;
                                }
                                return(
                                    <div className='userAttempt prFlexRow'>
                                        <div style={{cursor:'pointer'}} onClick={()=>navigate('/room/'+el.id)}>
                                            <div className='prFlexRow'>
                                                <Icon type='playController'></Icon>
                                                <div className='userAttemptStatus'>
                                                    {list[el.status]}
                                                </div>
                                            </div>
                                            <div className='userAttemptTime'>
                                                <TimerPR initialSeconds={el.termExistence} />
                                            </div>
                                        </div>
                                    </div>
                                )
                                })}
                        </div>
                         }
                    </div>}
                    

                    <div className='prFlexRow headerMoney'>
                        <div className='headerMoneyWallet'><Icon type='wallet'></Icon></div>
                        <div>
                            <div className='prFlexRow'>
                                <Icon type='fTocken'></Icon>
                                <div>
                                    <span className='prTockenEvailable'>{user?.transactions?.balance_fiat} </span>/<span className='prTockenBlocked'> {user?.transactions?.balance_fiat_frozen}</span>
                                </div>
                            </div>
                            <div className='prFlexRow'>
                                <Icon type='cTocken'></Icon>
                                <div>
                                    <span className='prTockenEvailable'>{user?.transactions?.balance_coin} </span>/<span className='prTockenBlocked'> {user?.transactions?.balance_coin_frozen}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='prFlexRow headerUser sideMenu' onClick={openMenu} ref={menuRef}>
                        <div className='headerUserAvatar'>
                            <Icon type='avatar'></Icon>
                        </div>
                        <div>
                            <div className='nickname'>
                                {user?.nickname}
                            </div>
                            <div className='prFlexRow'>
                                <span className='reputation'>{t('reputation')}:</span> <div className={
                                        (user?.reputation > 6 && "reputationNumber") ||
                                        ((user?.reputation < 7 && user?.reputation > 3) && "reputationNumber caution") ||
                                        (user?.reputation < 4 && "reputationNumber error")
                                    }>{user?.reputation} {(user?.reputation > 4 && t('points')) || (user?.reputation > 1 && t('pointss')) || (user?.reputation === 1 && t('point')) || (user?.reputation === 0 && t('points'))}</div>
                            </div>
                        </div>

                        {open && <div className='openSideMenu'>
                            <div className='openSideMenuNavigation'>
                                <div onClick={()=>navigate('/profile/rooms')}>{t('rooms')}</div>
                                <div onClick={()=>navigate('/profile/wallet')}>{t('wallet')}</div>
                                <div onClick={()=>navigate('/profile/varification')}>{t('accaunt_verifcation')}</div>
                                <div onClick={()=>navigate('/profile/settings')}>{t('settings')}</div>
                            </div>
                            <div className='openSideMenuExit' onClick={logOutClick}>
                                <Icon type='exit' />  {t('log_out')}
                            </div>
                        </div>}
                    </div>

                    <div className='headerButtons prFlexRow'>
                        <div onClick={()=>navigate('/supportList')}><Icon type='support'></Icon></div>
                       
                        <div className='sideMenu' ref={menuNotificationRef}>
                            <div onClick={openNotification}>
                                <Icon type='notification'></Icon>
                            </div>
                            {openNotifications && <div className='openSideMenu openSideMenuBig openSideMenuRight'>
                                <div className='openSideMenuNotifications'>
                                    <h3>Повідомлення</h3>
                                    <div className='openSideMenuNotificationsBody'>
                                    {notifications.map(el=>{
                                        return(
                                        <div className='notificationItem'  onClick={() => {navigate('/room/'+el.roomId);openNotification();}}>
                                            <div className='dFlex'>
                                                <div className={el.isRead===1?'read':'read unread'}>
                                                </div>
                                                <div>
                                                    <div className='notificationItemHeader'>
                                                        {el.type===0 && 'Запит адміністратора: '}
                                                        {el.type===1 && 'Запит арбітра: '}
                                                        {el.type===2 && `Вас запрошено в гру ${el.description}: `}
                                                        <span>{el.nickname}</span>
                                                    </div>
                                                   {el.type!==2 && <div className='notificationItemText'>
                                                        {el.description}
                                                    </div>}
                                                    <div className='dFlex notificationItemTime'>
                                                        <Icon type='time'></Icon>  {el.time}
                                                    </div>
                                                </div>
                                                
                                            </div>
                                            
                                        </div>
                                        )
                                        
                                    })}
                                     </div>
                                                                    </div>
                            </div>}
                        </div>
                        <div className='sideMenu' ref={menuRightRef}>
                            <div onClick={openMenuRight}>
                                <Icon type='more'></Icon>
                            </div>
                            {openRight && <div className='openSideMenu openSideMenuRight'>
                                <div className='openSideMenuNavigation'>
                                    <div  onClick={() => {navigate('/users');openMenuRight();}}>{t('users_all')}</div>
                                    <div>{t('Dictionary')}</div>
                                    <div  onClick={() => {navigate('/directory');openMenuRight();}}>{t('directory')}</div>
                                    <div  onClick={() => {navigate('/games');openMenuRight();}}>{t('games')}</div>
                                    <div  onClick={() => {navigate('/gamesLibrary');openMenuRight();}}>{t('games_library')}</div>
                                </div>
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
        </div>

            {isOpenCreate && <CreateRoom isOpen={isOpenCreate} setIsOpen={setIsOpenCreate} ></CreateRoom>}
        </>
    );
};

export default HeaderAuth;
