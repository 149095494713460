function getWordEnding(count) {
  if (count % 10 === 1 && count % 100 !== 11) {
    return "запис"; // 1, 21, 31, ...
  } else if (
    [2, 3, 4].includes(count % 10) &&
    ![12, 13, 14].includes(count % 100)
  ) {
    return "записи"; // 2-4, 22-24, ...
  } else {
    return "записів"; // 0, 5-20, 25-30, ...
  }
}

export default getWordEnding;