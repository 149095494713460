import React, { useEffect, useState } from 'react';

const TimerPR = ({ initialSeconds,setTimerValue, timerValue}) => {
    const [seconds, setSeconds] = useState(initialSeconds);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setSeconds(prev => (prev > 0 ? prev - 1 : 0));
            if(seconds<1 && timerValue){
                setTimerValue(0);
            }
        }, 1000);

        // Clear interval on component unmount
        return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
        setSeconds(initialSeconds);
       
    }, [initialSeconds]);

    // Convert seconds to mm:ss format
    const minutes = Math.floor(seconds / 60).toString().padStart(2, '0');
    const remainingSeconds = (seconds % 60).toString().padStart(2, '0');

    return <span>{minutes}:{remainingSeconds}</span>;
};

export default TimerPR;