import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Icon } from "../../../components/Icon";
import getDate from "../../../helpers/getDate";
import { getSupportDetail } from "../../../redux/actions/support";


const SupportItemPage =()=>{
    const { t } = useTranslation();
    const [ready, setReady] = useState(false);
    const currentId = useParams().id;
    const navigate = useNavigate();
    const dispatch= useDispatch();
    const support = useSelector((state) => state.support.currentsupport?.message);
    const list = useSelector(state=>state.list)


    const initFunc =(page)=>{
        dispatch(getSupportDetail({id:currentId})).then(res=>{
            setReady(true)
        })
    }

    useEffect(() => {
        if(!ready){
            initFunc();
        }
    }, [ready]);

    return(<>
         {ready &&<div className="profileSection" style={{marginBottom:'0px'}}>
                <div className="prFlexRow profileSectionHeader" style={{marginBottom:'40px'}}>
                    <h2 >
                        Запит #{support.id} - {support.message}
                    </h2>
                </div>
                <div className="dFlex" style={{gap:'21px'}}>
                    <div className="supportAlign">
                        <div className="createSupport createSupportLeft">
                            <div className="createSupportHeaderSup">Інформація про запит</div>
                            <div>
                                <div className="createSupportTextHeader">Статус</div>
                                <div className="createSupportText">{list[support.status]}</div>
                            </div>
                            <div>
                                <div className="createSupportTextHeader">Статус</div>
                                <div className="createSupportText">{list[support.status]}</div>
                            </div>
                            <div>
                                <div className="createSupportTextHeader">Користувач</div>
                                <div className="createSupportText">{support.name}</div>
                            </div>
                            <div>
                                <div className="createSupportTextHeader">Відділ</div>
                                <div className="createSupportText">{list[support.department]}</div>
                            </div>


                            <div>
                                <div className="createSupportTextHeader">Час створення</div>
                                <div className="dFlex createSupportText"> 
                                    <div className="roomItemBlockValue"><Icon type='time'></Icon> {getDate(support.createdAt?.replace('T',' '),'hh:mm',':')}</div>
                                    <div style={{marginLeft:'18px'}} className="roomItemBlockValue"><Icon type='calendar'></Icon>{getDate(support.createdAt?.replace('T',' '),'dd/mm/yyyy','.')}</div>
                                </div>
                            </div>
                            <div>
                                <div className="createSupportTextHeader">Оновлення</div>
                                <div className="dFlex createSupportText"> 
                                    <div className="roomItemBlockValue"><Icon type='time'></Icon> {getDate(support.createdAt?.replace('T',' '),'hh:mm',':')}</div>
                                    <div style={{marginLeft:'18px'}} className="roomItemBlockValue"><Icon type='calendar'></Icon>{getDate(support.updatedAt?.replace('T',' '),'dd/mm/yyyy','.')}</div>
                                </div>
                            </div>

                            <div>
                                <div className="createSupportTextHeader">Приоритет</div>
                                <div className="createSupportText">{list[support.priority]}</div>
                            </div>
                            
                        </div>
                            <div className="dFlex buttonSize" style={{gap:'20px', marginTop:'50px', marginBottom:'20px'}}>
                                <button className="prButton prButtonSecond"   onClick={()=>navigate('/supportList')}>Закрити</button>
                                <button className="prButton prButtonMain"   onClick={()=>navigate('/supportList')}>Відповісти</button>
                            </div>
                            <button className="prButton prButtonMain"   onClick={()=>navigate('/supportList')}>До списку запитів</button>                        

                            <div className="createSupport createSupportLeft" style={{marginTop:'50px'}}>
                            <div className="createSupportHeaderSup">Вкладення</div>
                           
                            <div className="downloadItems dFlex">
                                <div><Icon type='download'></Icon></div>
                                <div className="createSupportTextHeader">Screenshot_5.jpg</div>
                            </div>
                            
                        </div>
                    </div>

                </div>




        </div>}
    </>)

}

export default SupportItemPage;