import { refreshToken } from './actions/auth';

function jwt({ dispatch, getState }) {
    return (next) => (action) => {
        if (typeof action === 'function') {
            const localData = JSON.parse(localStorage.getItem('userData'));
            if (localData && localData.accessToken) {
                const token = JSON.parse(atob(localData.accessToken.split('.')[1]));
                const tokenExpiration = token.exp * 1000 - Date.now();
                if (tokenExpiration < 0) {
                    if (!getState().auth.freshTokenPromise) {
                        const refreshPromise = refreshToken(dispatch, localData.refreshToken);
                        getState().auth.freshTokenPromise = refreshPromise;
                        return refreshPromise
                            .then(() => next(action))
                            .finally(() => {
                                getState().auth.freshTokenPromise = null;
                            });
                    } else {
                        return getState().auth.freshTokenPromise
                            .then(() => next(action));
                    }
                }
            }
        }
        return next(action);
    };
}

export default jwt;