import { combineReducers } from "redux";
import auth from "./auth";
import room from "./room";
import user from "./user";
import support from "./support";
import currentUser from "./currentUser";
import currentRoom from "./currentRoom";
import fullList from './fullList';
import list from './list';
import error from './error';
import game from './game';

export default combineReducers({
  auth,
  currentUser,
  room,
  user,
  game,
  currentRoom,
  support,
  fullList,
  list,
  error,
  });
