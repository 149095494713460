import React, { useEffect, useState } from "react";
import { Icon } from "../../components/Icon";
import DatePicker from "../../components/DatePicker/DatePicker";
import { useTranslation } from "react-i18next";
import Pagination from "../../components/Pagination/Pagination";
import { useDispatch, useSelector } from "react-redux";
import { getUsersList, createUser } from "../../redux/actions/user";
import { useNavigate } from "react-router-dom";
import Filter from "../../components/Filter/Filter";
import getDate from "../../helpers/getDate";

const sortOptions = [
    { id: "createdAt", value: "За датою створення" },
];

const UsersPage = () => {
    const { t } = useTranslation();
    const [ready, setReady] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [pageCur, setPageCur] = useState(1);
    const [filter, setFilter] = useState(() => {
        // Initialize filter from localStorage if available
        const savedFilter = localStorage.getItem("usersPage");
        return savedFilter ? JSON.parse(savedFilter) : {};
    });

    const [sort,setSort]=useState('asc');
    const [sortValue,setSortValue]=useState('createdAt');

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector((state) => state.user);

    const initFunc = (page = 1,fil) => {
        let filt={...filter}
        if(fil){
            if (fil.target.id === "dateFrom" || fil.target.id === "dateTo") {
                filt[fil.target.id]= getDate(fil.target.value, "yyyy/mm/dd", "-");
            } 
        }
        let data = {};
        data.limit = 20;
        data.offset = (page - 1) * data.limit;
        data.filter = filt;
        data.sort={};
        data.sort[sortValue]=sort;

        dispatch(getUsersList(data)).then(() => {
            setReady(true);
        });
    };

    const changeHandler = (e) => {
        const { id, value } = e.target;
        let updatedFilter = { ...filter };

        if (e.target.tagName === 'SELECT' && e.target.accessKey) {
            updatedFilter[e.target.accessKey] = value;
        }
        else 
        if (id === "dataBirthdayFrom" || id === "dataBirthdayTo") {
            updatedFilter[id] = getDate(value, "yyyy/mm/dd", "-");
        } 
        else if (id === "all" && value.length<3 && value.length>0) {
           
        } 
        else if (value.length<1) {
            delete updatedFilter[id];
        } 
        else {
            updatedFilter[id] = value;
        }
        setFilter(updatedFilter);
    };

    const savefilter =()=>{
        localStorage.setItem("usersPage", JSON.stringify(filter));
    }
    const resetFilter=()=>{
        // localStorage.removeItem('usersPage');
        setFilter({});
    }
    
    

    // useEffect(() => {
    //     if (ready) {
    //         initFunc();
    //     }
    // }, [filter]);

    useEffect(() => {
        if (!ready) {
            initFunc();
        }
    }, [ready]);

    useEffect(() => {
        if (ready) {
            initFunc();
        }
    }, [sort]);


    const createClick = () => {
        dispatch(createUser())
            .then((res) => {
                navigate(`/userEdit/${res.id}`);
            })
            .catch((e) => {
                console.error(e);
            });
    };

    const editClick = (id) => {
        navigate(`/userEdit/${id}`);
    };

    return (
        <div className="profileSection" style={{ marginBottom: "0px" }}>
            <div className="prFlexRow profileSectionHeader">
                <h2>
                    {t("users_all")}
                    <div className="infoHelp">
                        <Icon type="info" />
                    </div>
                </h2>
                <button className="prButton prButtonMain" onClick={createClick}>
                    {t("users_create")}
                </button>
            </div>

            <div className="filterBlock">
                <div className="prFlexRow">
                    <div className="prFlexRow">
                        <div className="prFlexRow filterBlockFilter">
                            <div className="filterBlockSortText">{t("filter_period")}:</div>
                            <DatePicker id="dataBirthdayFrom" value={(filter.dataBirthdayFrom?.length>1 && getDate(filter.dataBirthdayFrom,'dd/mm/yyyy','.')) || ''} onChange={changeHandler} onBlur={initFunc} icon={true} />
                            <div>-</div>
                            <DatePicker id="dataBirthdayTo" value={(filter.dataBirthdayTo?.length>1 && getDate(filter.dataBirthdayTo,'dd/mm/yyyy','.')) || ''} onChange={changeHandler}  onBlur={initFunc} icon={true} />
                        </div>
                        <div className="filterBlockSort prFlexRow">
                            <div className="filterBlockSortText">{t("filter_sort")}:</div>
                            <div className="filterBlockSortValue prFlexRow" onClick={()=>setSort(sort=='asc'?'desc':'asc')}>
                                <div className={sort=='asc'?'norm':'pervert'}><Icon type="filterArrow" /></div>
                                <div>{sortOptions[0].value}</div>
                                <Icon type="selectArrow" />
                            </div>
                        </div>
                    </div>
                    <div>
                        <button className="prButton prButtonSecond prFlexRow" onClick={() => setIsOpen(true)}>
                            <Icon type="filter" /> {t("filter_show")}
                        </button>
                    </div>
                </div>
                <div className="filterBlockFilteredText">
                    {t("filter_find")} {user.totalFilter} {t("filter_all")} {user.total}
                </div>
            </div>

            {ready && (
                <>
                    <div className="userList">
                        {user.list?.length > 0 &&
                            user.list.map((el) => (
                                <div className="userItem" key={el.id}>
                                    <div className="headerUserAvatar">
                                        <Icon type="avatar" />
                                        <div
                                            className={
                                                (el?.reputation > 6 && "headerUserAvatarReputation") ||
                                                ((el?.reputation < 7 && el?.reputation > 3) && "headerUserAvatarReputation caution") ||
                                                (el?.reputation < 4 && "headerUserAvatarReputation error")
                                            }
                                        >
                                            {el.reputation}
                                        </div>
                                    </div>
                                    <div>
                                        <div className="userItemNick" onClick={() => editClick(el.id)}>
                                            {el.nickname} <Icon type="editName" />
                                        </div>
                                        <div className="prFlexRow statistic">
                                            <div className="prFlexRow white">
                                                <Icon type="playController" />
                                                <div className="statisticValue">&nbsp;: 42</div>
                                            </div>
                                            <div className="prFlexRow green">
                                                <Icon type="win" />
                                                <div className="statisticValue">&nbsp;: 30</div>
                                            </div>
                                            <div className="prFlexRow red">
                                                <Icon type="loose" />
                                                <div className="statisticValue">&nbsp;: 10</div>
                                            </div>
                                            <div className="prFlexRow orange">
                                                <Icon type="draft" />
                                                <div className="statisticValue">&nbsp;: 2</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                    </div>

                    {user.totalFilter>user.limit&& <Pagination
                        className="pagination-bar"
                        currentPage={pageCur}
                        totalCount={user.totalFilter}
                        pageSize={user.limit}
                        onPageChange={(page) => {
                            setPageCur(page);
                            initFunc(page);
                        }}
                    />}

                    {isOpen && <Filter type='users' savefilter={savefilter} resetFilter={resetFilter} accept={initFunc} filter={filter} setFilter={setFilter} onChange={changeHandler} isOpen={isOpen} setIsOpen={setIsOpen} />}
                </>
            )}
        </div>
    );
};

export default UsersPage;
