import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { createRoom } from "../../redux/actions/room";
import Filter from "../Filter/Filter";


const CreateRoom=(props)=>{
    const [filterCreate, setFilterCreate] = useState({});
    const [isOpenCreate, setIsOpenCreate] = useState(false);
    
    const dispatch =useDispatch();

    const selectClick=(e)=>{
        e.preventDefault();
        let arr = [];
        if(filterCreate.platform?.length>0){
            arr=[...filterCreate.platform]
        }
        let el = e.target.id;
        if (arr.indexOf(el) !== -1) {
            arr = arr.filter(item => item !== el);
        } else {
            arr.push(el);
        }
        setFilterCreate({
            ...filterCreate,
            platform: arr
        });
    }
    const resetFilterCreate=()=>{
        setFilterCreate({});
    }
    const createFunc =()=>{
        let data = filterCreate;
        if(data.selectedGame){
        }else{
            data.selectedGame=props.game.id;
            data.termExistence=props.game.termExistence;
        }
        dispatch(createRoom(filterCreate)).then(res=>{
            console.log(res);
        })
    }
    const changeHandlerCreate = (e) => {
        const { id, value } = e.target;
        let updatedFilterCreate = { ...filterCreate };

        if (e.target.tagName === 'SELECT' && e.target.accessKey) {
            updatedFilterCreate[e.target.accessKey] = value;
        }
        else if (value.length<1) {
            delete updatedFilterCreate[id];
        }
        else {
            updatedFilterCreate[id] = value;
        }
        setFilterCreate(updatedFilterCreate);
    };
    const setIsOpenCreateClick=()=>{
        setIsOpenCreate(!isOpenCreate);
        props.setIsOpen(!isOpenCreate);
    }

    useEffect(() => {
        setIsOpenCreate(props.isOpen) 
       
    }, [props.isOpen]);
    return(
        <>
            {isOpenCreate && <Filter type='createRooms' game={props.game} selectClick={selectClick} needCheckedC={true} resetFilter={resetFilterCreate} accept={createFunc} filter={filterCreate} setFilter={setFilterCreate} onChange={changeHandlerCreate} isOpen={isOpenCreate} setIsOpen={setIsOpenCreateClick} />}
        </>
    )
}

export default CreateRoom;