import {
  GETSUPPORTDETAIL_FAIL,
  GETSUPPORTDETAIL_SUCCESS,
  GETSUPPORTSLIST_FAIL,
  GETSUPPORTSLIST_SUCCESS,
  CREATESUPPORT_SUCCESS,
  CREATESUPPORT_FAIL,
  EDITSUPPORT_FAIL,
  EDITSUPPORT_SUCCESS
} from "../actions/types";

const initialState = {};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GETSUPPORTDETAIL_SUCCESS:
      return {
        ...state,
        currentsupport:payload
      };
    case GETSUPPORTDETAIL_FAIL:
      return {
        ...state
      };
    case GETSUPPORTSLIST_FAIL:
      return {
        ...state
      };
    case GETSUPPORTSLIST_SUCCESS:
      return {
        list:[...payload.result],
        total:payload.total,
        totalFilter:payload.totalFilter,
        limit:payload.limit,
        offset:payload.offset
      };
    case CREATESUPPORT_SUCCESS:
     return {
        ...state,
        support:{...payload}
      };
    case CREATESUPPORT_FAIL:
      return {
        ...state
      };
    case EDITSUPPORT_FAIL:
      return {
        ...state
      };
    case EDITSUPPORT_SUCCESS:
      return {
        ...state
      };
    default:
      return state;
  }
}
